export const spiritualHealing = {
  description: `
<p>Human beings have a bioenergy field around them that looks a bit like an egg. Chakras are like gates that step down frequencies from the source field into our bodies. They are located along the midline of the body. Energy is channelled into the local endocrine gland, nerve plexus and on to the meridian network and out to every part of the body.</p>
<p>With disease, chakras can become torn, closed or dysfunctional.</p>
<p>During a spiritual healing session, we may restructure the chakras, remove clouds and mucus and place healing light into each energy centre.</p>
  `,
  testimonials: `
<p>I had 3 months of very challenging health conditions that nothing I was doing seemed to help. I went to Charlotte and she suggested doing some energy clearing along with a chakra balance. This made a huge difference to my overall health and has put me back on track towards managing my health and wellbeing and other challenges coming my way.</p>
<p>I am incredibly grateful to Charlotte for this work and would highly recommend her.</p>
<i>Sarah, Nelson</i>
<p>
  I felt a significant shift after having my chakras balanced by Charlotte.
  Immediately, I felt waves of nausea until I had to purge. It was like feeling
  the seasickness of choppy seas as I regained my balance. I went to Charlotte
  due to low energy and a feeling of tension in my body. One treatment shifted
  this, releasing the tension I was holding and returning my energy and
  motivation. I am feeling lighter, clearer and more expansive. What a relief.
  Thank you Charlotte!
</p>
<i>FD</i>
  `,
  additional: `
<p>
  This is a recent training that I received from the Neo Shamanic Society by
  Christof Melchizedek. Many thousands of healers around the world are currently
  offering this intervention for people affected by the recent vaccinations. If
  you have felt unwell mentally or physically since the injections then please
  call me and I will explain what the session involves.
</p>
<h6>Testimonial</h6>
<p>I had one jab and regretted it. I was worried about having it in my body. I was also beating myself up about getting jabbed in the first place. I was living my life in a black cloud of overwhelming worry and regret. </p>
<p>I heard about Charlotte's vaccine clearing treatment and decided to give it a go. The treatment was gentle and relaxing. Afterwards, it felt like the black cloud had lifted. I felt lighter, more trusting and accepting and a lot more positive. </p>
<p>It has been six months since I had my treatment and the black cloud has not returned. I am very, very grateful to Charlotte for her part in getting me back to a more balanced state.</p>
<i>AMF</i>
`,
};
