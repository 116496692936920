import { createIcon } from "@chakra-ui/react";
import React from "react";

export const CalendarIcon = createIcon({
  displayName: "CalendarIcon",
  viewBox: "0 0 30 30",
  path: (
    <>
      <path fill='currentColor'
        d="M9,2.8c-0.4,0-0.8,0.3-0.8,0.8v3C7.3,6.9,6.7,7.7,6.7,8.7c0,1.3,1.1,2.3,2.3,2.3s2.3-1.1,2.3-2.3
	c0-1-0.7-1.9-1.6-2.2v-3C9.8,3.1,9.5,2.8,9,2.8z M21,2.8c-0.4,0-0.8,0.3-0.8,0.8v3c-0.9,0.3-1.6,1.2-1.6,2.2c0,1.3,1.1,2.3,2.3,2.3
	c1.3,0,2.3-1.1,2.3-2.3c0-1-0.7-1.9-1.6-2.2v-3C21.8,3.1,21.4,2.8,21,2.8z M5.4,4.3c-1.6,0-2.9,1.3-2.9,2.9v17.2
	c0,1.6,1.3,2.9,2.9,2.9h19.3c1.6,0,2.9-1.3,2.9-2.9V7.2c0-1.6-1.3-2.9-2.9-2.9h-1.6c-0.4,0-0.8,0.4-0.8,0.8s0.4,0.8,0.8,0.8h1.6
	c0.7,0,1.3,0.6,1.3,1.3v4.4H4.1V7.2c0-0.7,0.6-1.3,1.3-1.3h1.6c0.4,0,0.8-0.4,0.8-0.8S7.3,4.3,6.9,4.3H5.4z M11.1,4.3
	c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8h7.8c0.4,0,0.8-0.3,0.8-0.8c0-0.4-0.3-0.8-0.8-0.8H11.1z M9,8c0.4,0,0.8,0.3,0.8,0.8
	c0,0.4-0.3,0.8-0.8,0.8c-0.4,0-0.8-0.3-0.8-0.8C8.2,8.3,8.6,8,9,8z M21,8c0.4,0,0.8,0.3,0.8,0.8c0,0.4-0.3,0.8-0.8,0.8
	s-0.8-0.3-0.8-0.8C20.2,8.3,20.6,8,21,8z M4.1,13.2h21.9v11.2c0,0.7-0.6,1.3-1.3,1.3H5.4c-0.7,0-1.3-0.6-1.3-1.3V13.2z"
      />
    </>
  ),
});

export default CalendarIcon;
