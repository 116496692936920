import YearsOfExperience from ".";

const SITE_CONFIG = {
  SITE_NAME: "Stillpoint Acupuncture and Craniosacral Therapy",
  SITE_ROOT_PATH: "/",
  SITE_URL: "https://stillpointacupuncture.co.nz",
  SITE_TAGLINE: "Healing Paths to Health and Wellness",
  SITE_DESCRIPTION:
    `Discover Japanese Acupuncture, Craniosacral Therapy, and Spiritual Healing by Charlotte Stuart in Nelson. ${YearsOfExperience}+ years of experience in a serene setting. Unleash your well-being potential today.`,
  OWNER_NAME: "Charlotte Stuart",
  CONTACT_NUMBER: "0272922333",
  ADDRESS: "159 Hardy St",
  CITY: "Nelson",
  POSTCODE: "7010",
  ADDRESS_URL: "https://maps.app.goo.gl/nWNDuc7dQPCUFzBz6",
  EMAIL: "charlottejunestuart@gmail.com",
  // use reCAPTCHA V2 tickbox, admin: archieweb.nz@gmail
  RECAPTCHA_SITE_KEY: "6Lde8BIbAAAAAFx38jchmwFxJbEOr5MKSqo8wUvc",
  RECAPTCHA_SECRET_KEY: "6Lde8BIbAAAAANgtnbqzbzp7_-ffnVg2QpdSCGLe",

  EMAILJS_serviceID: "service_mailJS",
  EMAILJS_templateID: "emailjs-contact-form",
  EMAILJS_userID: "RKOUmKaym8g2Xh6B7", // API keys under account
};

export default SITE_CONFIG;
