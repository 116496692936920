import LandingScreen from "components/LandingScreen";
import IMAGEURL from "constants/index";
import SPACING from "constants/spacing";
import { faq, introText, poem, treatments } from "data";
import ContactSection from "pages/Contact";
import { AboutSection } from "./AboutSection";
import { FaqSection } from "./FaqSection";
import { TreatmentRoom } from "./TreatmentRoom";
import { TreatmentSection } from "./TreatmentSection";
import ReactHelmet from "components/MetaHelmet";
import SITE_CONFIG from "constants/siteConfig";

const Homepage = () => {
  // Notice the use of %PUBLIC_URL% in the tags index.html.
  // It will be replaced with the URL of the `public` folder during the build.
  // Only files inside the `public` folder can be referenced from the HTML.

  // Unlike "/favicon.ico" or "favicon.ico", "%PUBLIC_URL%/favicon.ico" will
  // work correctly both with client-side routing and a non-root public URL.
  // Learn how to configure a non-root public URL by running `npm run build`.

  /*
    To fix the warning: Using UNSAFE_componentWillMount in strict mode 
    update the code to use componentDidMount instead of UNSAFE_componentWillMount. 
    This will ensure that your code is compatible with the latest version of React and avoids potential bugs. 
  */

  return (
    <>
      {/* <ReactHelmet /> */}

      {/* <Helmet>
        <link rel='canonical' href={SITE_CONFIG.SITE_URL} />
        <meta
          property='og:description'
          content={SITE_CONFIG.SITE_DESCRIPTION}
        />
        <meta property='og:url' content={SITE_CONFIG.SITE_URL} />
        <meta property='og:image' content='/images/img-about.jpg' />
        <meta name='description' content={SITE_CONFIG.SITE_DESCRIPTION} />
        <meta property='og:title' content={SITE_CONFIG.SITE_NAME} />
        <title>
          {SITE_CONFIG.SITE_NAME} | {SITE_CONFIG.SITE_TAGLINE}
        </title>
      </Helmet> */}
      <LandingScreen
        backgroundImage={IMAGEURL.bgPattern}
        pb={SPACING.PADDING}
        introText={introText}
      />

      <TreatmentSection
        id='treatments'
        py={SPACING.PADDING}
        DATA={treatments}
      />
      <TreatmentRoom imgName='bg-room' poem={poem} />
      <AboutSection
        id='about-me'
        px={0}
        // my={{ "3xl": 24 }}
        imgName='img-charlotte_stuart'
      />
      <FaqSection
        id='faq'
        bgColor='auburn.400'
        py={SPACING.PADDING}
        accordionItems={faq}
      />
      <ContactSection
        backgroundImage={IMAGEURL.bgPattern}
        id='contact'
        bgColor={"sa_teal.300"}
      />
    </>
  );
};

export default Homepage;
