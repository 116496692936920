import { createIcon } from "@chakra-ui/react";
import React from "react";

export const TickRoundedIcon = createIcon({
  displayName: "TickRoundedIcon",
  viewBox: "0 0 30 30",
  path: (
    <>
      <path
        fill="currentColor"
        d="M15,0C6.7,0,0,6.7,0,15s6.7,15,15,15s15-6.7,15-15S23.3,0,15,0z M15,28.5c-7.5,0-13.5-6-13.5-13.5
	S7.5,1.5,15,1.5s13.5,6,13.5,13.5S22.5,28.5,15,28.5z"
      />
      <path
        fill="currentColor"
        d="M20.6,10.8c0.2,0.2,0.3,0.6,0.1,0.8l-5.9,7.1c-0.1,0.1-0.2,0.2-0.4,0.2s-0.3,0-0.4-0.1l-3.9-3.4
	c-0.2-0.2-0.3-0.6-0.1-0.8s0.6-0.3,0.8-0.1l3.5,3l5.5-6.6C20,10.7,20.4,10.6,20.6,10.8z"
      />
    </>
  ),
});

export default TickRoundedIcon;
