import { createIcon } from "@chakra-ui/react";
import React from "react";

export const FilterIcon = createIcon({
  displayName: "FilterIcon",
  viewBox: "0 0 30 30",
  path: (
    <>
      <polygon
        fill="currentColor"
        points="8.8,7.5 8.8,5 6.2,5 6.2,7.5 2.5,7.5 2.5,10 6.2,10 6.2,12.5 8.8,12.5 8.8,10 27.5,10 27.5,7.5"
      />
      <polygon
        fill="currentColor"
        points="22.5,17.5 20,17.5 20,20 2.5,20 2.5,22.5 20,22.5 20,25 22.5,25 22.5,22.5 27.5,22.5 27.5,20 22.5,20"
      />
    </>
  ),
});

export default FilterIcon;
