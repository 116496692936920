import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
} from "@chakra-ui/react";
import WebpImage from "components/WebpImage";
import { modalContentStyle } from "constants/globalstyle";
import parse from "html-react-parser";
import { MinusIcon, PlusIcon } from "../../icons";
import { H2, H5 } from "../../Typography";

interface Props {
  heading?: string;
  accordionItems?: any;
}

export const FaqAccordion = ({ heading, accordionItems }: Props) => {
  return (
    <>
      <H2 color='white' textAlign={"center"} mb={{ base: 8, lg: 12 }}>
        {heading ? heading : "FAQ"}
      </H2>
      <Accordion allowToggle allowMultiple>
        {accordionItems.map((faq: any, index: number) => (
          <AccordionItem key={index} borderColor='whiteAlpha.600' color='white'>
            {({ isExpanded }) => (
              <>
                <Box>
                  <AccordionButton
                    _focus={{
                      boxShadow: 0,
                    }}
                    // sx={{
                    //   '&[aria-expanded="true"]': {
                    //     borderTopWidth: "1px",
                    //   },
                    // }}
                    px={0}
                    py={6}
                  >
                    <Box flex='1' textAlign='left'>
                      <H5 as="h3" mr={3}>{faq.title}</H5>
                    </Box>
                    {isExpanded ? (
                      <MinusIcon boxSize={{ base: 5, lg: 8 }} color='white' />
                    ) : (
                      <PlusIcon boxSize={{ base: 5, lg: 8 }} />
                    )}
                  </AccordionButton>
                </Box>
                <AccordionPanel px={0} pb={6}>
                  <Box sx={modalContentStyle}>{parse(faq.bodyText)}</Box>
                  {faq.faqImage && (
                    <WebpImage
                      w='full'
                      objectFit='cover'
                      imgName={faq.faqImage}
                      mt={4}
                    />
                  )}
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        ))}
      </Accordion>
    </>
  );
};
