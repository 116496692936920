import { createIcon } from "@chakra-ui/react";
import React from "react";

export const NewIcon = createIcon({
  displayName: "NewIcon",
  viewBox: "0 0 64 64",
  path: (
    <>
      <path fill='currentColor' d="M47.4 16.1a17.06 17.06 0 00-9-9.5c-2-.9-4.2-1.3-6.4-1.3s-4.4.5-6.4 1.3a17.06 17.06 0 00-9 9.5c-.8 2.1-1.3 4.4-1.2 6.7v18.4c0 4.6 1.8 9 4.9 12.3a16.2 16.2 0 0023.6 0c3.2-3.3 4.9-7.7 4.9-12.3V22.8c-.1-2.3-.6-4.5-1.4-6.7zm-2.3 25.2c0 3.6-1.4 7.1-3.8 9.7a12.8 12.8 0 01-18.6 0c-2.5-2.6-3.8-6.1-3.8-9.7V22.8c0-3.6 1.4-7.1 3.8-9.7a12.8 12.8 0 0118.6 0c2.5 2.6 3.8 6.1 3.8 9.7v18.5z" />
      <path fill='currentColor' d="M37.4 25.2L33 29.7v-11h-2v11l-4.4-4.4c-.4-.4-1-.4-1.3 0-.4.4-.4 1 0 1.3l6.1 6.1c.4.4 1 .4 1.3 0l6.1-6.1c.4-.4.4-1 0-1.3-.4-.4-1-.4-1.4-.1z" />
    </>
  ),
});

export default NewIcon;
