import { Box, Flex, HStack, Link, Spacer } from "@chakra-ui/react";
import MobileMenu from "components/Navigation/MobileMenu";
// import IMAGEURL from "constants/index";
import { useState } from "react";
import Headroom from "react-headroom";
import { PhoneIcon } from "theme/ui-components/icons";
import ContactDetails from "../ContactDetails";

export const Header = () => {
  // console.log("current path " + useLocation().pathname);
  const [onpin, setOnPin] = useState(false);
  // console.log(onpin);

  return (
    <Headroom
      onPin={() => setOnPin(true)}
      // wrapperStyle={{ marginBottom: '24px' }}
      style={{
        // background: BGCOLOR,
        zIndex: 999,
      }}
    >
      <Box
        as='header'
        bg='sa_teal.200'
        py={{ base: 4, lg: 5 }}
        px={{ base: 4, lg: 10 }}
        transition='all 0.3s'
      >
        <Flex width='100%' align='center'>
          <MobileMenu />
          <Spacer />
          <HStack spacing={8} display={{ base: "none", lg: "block" }}>
            <ContactDetails />
          </HStack>
          <Spacer />
          <Link
            display={{ base: "block", lg: "none" }}
            href={`tel:${process.env.REACT_APP_NUMBER}`}
            aria-label={`${process.env.REACT_APP_NUMBER}`}
          >
            <PhoneIcon boxSize={8} color='tertiary' />
          </Link>
        </Flex>
      </Box>
    </Headroom>
  );
};
