// import colors from "./foundations/colors";

const styles = {
  global: {
    html: {
      scrollBehavior: "smooth",
    },
    body: {
      color: "#212121",
      fontSize: "16px",
      lineHeight: "1.68",
      position: "relative",
    },
    input: {
      outlineOffset: "3px",
    },
  },
};

export default styles;
