import { createIcon } from "@chakra-ui/react";
import React from "react";
// the basic icon template to start with
export const DemoIcon = createIcon({
  displayName: "DemoIcon",
  viewBox: "0 0 240 240",
  // d: 'M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
  path: (
    <>
      <circle cx="120" cy="120" r="120" fill="currentColor" />
      <path
        d="M119.599 205.485L41.049 69.431h157.1l-78.55 136.054z"
        fill="currentColor"
      />
      <rect x="99" y="99" width="44" height="44" fill="currentColor" />
    </>
  ),
});

export default DemoIcon;
