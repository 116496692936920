import { createIcon } from '@chakra-ui/react';
import React from 'react';

export const EnquiryCircle = createIcon({
  displayName: 'EnquiryCircle',
  viewBox: '0 0 30 30',
  path: (
    <>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M15 0a15 15 0 1 0 0 30 15 15 0 0 0 0-30zm0 28.5c-7.5 0-13.5-6-13.5-13.5S7.5 1.5 15 1.5s13.5 6 13.5 13.5-6 13.5-13.5 13.5z"
        clipRule="evenodd"
      />
      <path
        className="inner-icon"
        fill="currentColor"
        d="m22.6 10-.9-.3H8.3c-.3 0-.7.1-.9.3-.3.3-.4.6-.4.9V19c0 .3.1.7.3.9l.9.3h13.4c.3 0 .7-.1.9-.3.2-.3.3-.5.3-.9v-8c.2-.4 0-.7-.2-1zm-1.6.8L15.2 16l-.2.1-.2-.1L9 10.8h12zM8.1 18.6v-7.2l4 3.5-4 3.7zm.8.6 3.9-3.6 1.2 1.1.9.3c.3 0 .7-.1.9-.3l1.2-1.1 3.9 3.6h-12zm13-.6-4-3.6 4-3.5v7.1z"
      />
    </>
  ),
});

export default EnquiryCircle;
