import { Box, Link } from "@chakra-ui/react";

// const thisYear = new Date().getFullYear();

export const Copyright = () => {
  return (
    <Box>
      &copy; {process.env.REACT_APP_LEGALNAME} {new Date().getFullYear()}
      {/* <Box as='span' mx='2' fontSize='xs' opacity='0.38'>
        |
      </Box>
      powered by &nbsp;
      <Link href='http://archieweb.nz' isExternal>
        ArchieWeb
      </Link> */}
    </Box>
  );
};
