import { Image, ImageProps } from "@chakra-ui/react";
import SITE_CONFIG from "constants/siteConfig";
import LazyLoad, { forceVisible } from "react-lazyload";

export interface WebpProps extends ImageProps {
  imgName: string;
  isPNG?: boolean;
  isForceVisible?: boolean;
  alt?: string;
}

const WebpImage = ({
  imgName,
  isPNG,
  isForceVisible = false,
  alt,
  ...props
}: WebpProps) => {
  isForceVisible && forceVisible();
  return (
    <LazyLoad offset={200} height={200} once>
      <picture>
        <source
          srcSet={`images/${imgName}${isPNG ? "_lossless.webp" : ".webp"}`}
          type='image/webp'
        />
        <Image
          src={`images/${imgName}${isPNG ? ".png" : ".jpg"}`}
          alt={alt || `${imgName} - ${SITE_CONFIG.SITE_NAME} `}
          {...props}
        />
      </picture>
    </LazyLoad>
  );
};

export default WebpImage;
