import { ErrorMessage, useField } from "formik";
import React, { ReactNode, useState } from "react";
import {
  Textarea,
  TextareaProps,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
} from "@chakra-ui/react";

export type TextareaControlProps = Omit<FormControlProps, "label"> &
  TextareaProps & {
    name: string;
    helpText?: string;
    children?: ReactNode;
    extendedText?: {
      label: string;
      body: string | ReactNode;
    };
  };

const TextareaControl = ({
  name,
  helpText,
  children,
  extendedText,
  ...props
}: TextareaControlProps) => {
  const [field, meta] = useField(name);
  const [isFocused, setFocused] = useState(false);
  const onFocus = () => setFocused(!isFocused);
  const onBlur = () => setFocused(false);
  const errorTouched = meta.error && meta.touched;

  return (
    <FormControl
      id={name}
      isInvalid={errorTouched ? true : false}
      isRequired={props.isRequired}
      mt={4}
      {...field}
    >
      <Textarea
        minH='300px'
        onFocus={onFocus}
        onBlur={onBlur}
        name={name}
        {...props}
      />

      <FormErrorMessage mt={2}>
        <ErrorMessage name={name} />
      </FormErrorMessage>
      {!errorTouched && helpText && (
        <FormHelperText as='span' fontSize='sm' pl={7} display='inline-block'>
          *{helpText}
        </FormHelperText>
      )}
    </FormControl>
  );
};
export default TextareaControl;
