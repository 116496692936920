import { createIcon } from "@chakra-ui/react";
import React from "react";

export const FacebookIcon = createIcon({
  displayName: "FacebookIcon",
  viewBox: "0 0 30 30",
  path: (
    <path fill='currentColor'
      d="M18.9,6.7h2.3V2.7c-0.5,0-1.7-0.2-3.3-0.2c-3.3,0-5.5,2-5.5,5.9v3.4H8.8v4.4h3.6v11.2h4.4V16.2h3.4l0.6-4.4
	h-4.1V8.8C16.9,7.5,17.2,6.7,18.9,6.7z"
    />
  ),
});

export default FacebookIcon;
