// import SITE_CONFIG from "./siteConfig";
// import SPACING from "./spacing";
// import ZINDEX from "./zIndex";

const IMAGEURL = {
  bgPattern: "url('/images/bg-pattern.jpg')",
};

// current year minus 1998
const yearsOfExperience = new Date().getFullYear() - 1998 + 1;

export { yearsOfExperience };
// export { SITE_CONFIG, SPACING, ZINDEX };
export default IMAGEURL;
