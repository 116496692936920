import React, { useState } from "react";
import { Flex } from "@chakra-ui/layout";
import { ArrowIcon } from "../../theme/ui-components/icons";

export const GoTopButton = () => {
  const [isActive, setActive] = useState(false);
  /* 定義與取得數值 */
  // var CurrentY = document.documentElement.scrollTop;
  /* Get the entire height of an element, including padding */
  // var docHeight = document.documentElement.scrollHeight;
  // var winHeight = window.innerHeight;
  // var scrollPercent = (docHeight / CurrentY) * 100;
  // var scrollPercentRounded = Math.round(winHeight / 4);

  // console.log(CurrentY, docHeight, winHeight / 2);

  /* when window scroll執行以下 */
  window.onscroll = () => {
    scrollFunction();
  };
  /* 當可scroll超過300以上才執行以下 */
  const scrollFunction = () => {
    document.documentElement.scrollTop > 300
      ? setActive(true)
      : setActive(false);
  };
  /* 按下go top的按鈕後回到最上層 */
  const backToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  return (
    <>
      <Flex
        position='fixed'
        bgColor='rgba(0,0,0,0.7)'
        color='white'
        align='center'
        justifyContent='center'
        boxSize={16}
        fontSize='sm'
        zIndex='99'
        cursor='pointer'
        borderRadius='full'
        bottom={{ base: "1.25em", md: "1rem" }}
        right={{ base: "1em", md: "1rem" }}
        opacity={isActive ? 1 : 0}
        border='none'
        transition='all, 0.33s'
        aria-label='Go top button'
        role='button'
        _hover={{
          bgColor: "black",
        }}
        onClick={() => {
          backToTop();
        }}
      >
        <ArrowIcon boxSize={6} transform='rotate(270deg)' />
      </Flex>
    </>
  );
};
