import {
  Box,
  BoxProps,
  Center,
  Image,
  keyframes,
  Link,
  ListItem,
  SimpleGrid,
  Stack,
  Text,
  UnorderedList,
  useDisclosure,
} from "@chakra-ui/react";
import { PopupModal } from "components/PopupModal";
import WebpImage from "components/WebpImage";
import SITE_CONFIG from "constants/siteConfig";
// import Reveal, { Fade } from "react-awesome-reveal";
import { useRef } from "react";
import uuid from "react-uuid";
import { H2 } from "theme/ui-components/Typography";

const fadingSlider = keyframes`
  from {
    opacity: 0;
    transform: translateX(10%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const qualifications = [
  "1984 Registered Nurse, Christchurch NZ",
  "1998 master's degree in Acupuncture, NIAOM, Seattle, USA",
  "2008 2-year Diploma Spiritual Healing from Brenda Davies School UK",
  "2012 to 2017 Kiko Matsumoto Style Acupuncture, various training.",
  "2017 Biodynamic Craniosacral Therapy training with Body Intelligence",
  "2018 Foundations in Neuro Acupuncture Mike Corridino",
  "2019 Kiko Matsumoto Style Acupuncture, 6 seminars",
  "2020 to 2021 Tsuyoshi Shimamura. Closing the gate, strategies to support cancer recovery",
  "2022 Spiritual Healing trainings with Christof Melchizedek. Vaccine Removal, Remote Healing",
];

interface Props extends BoxProps {
  imgName: string;
}

export const AboutSection = ({ imgName, ...props }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef(null);
  return (
    <>
      <Box
        as='section'
        display='flex'
        alignItems='center'
        w='full'
        maxW='1920px'
        mx='auto'
        {...props}
      >
        <SimpleGrid columns={{ base: 1, lg: 2 }}>
          <Box>
            <WebpImage
              imgName={imgName}
              alt={SITE_CONFIG.OWNER_NAME}
              w='full'
              h='full'
              objectFit='cover'
            />
          </Box>
          <Center
          // bgColor='sa_teal.300'
          >
            <Box width={"80%"} py={20}>
              <H2 color='primary' mb='8'>
                About Me
              </H2>
              <Stack spacing={4}>
                <p>
                  Since an early age, I have been interested in the development
                  of consciousness and finding the truth contained within the
                  mind and body. After 30 years as a practicing Buddhist and
                  healer, I still feel just as eager and motivated to help
                  myself and others reach their full potential.
                </p>
                <p>
                  Japanese Acupuncture, Biodynamic Craniosacral, and spiritual
                  healing have provided good maps and guides to address injuries
                  of the mind and body that present to me in my clinic. I aim to
                  provide a neutral, calm, and safe container for health to
                  emerge. Working with each individual as beautifully unique and
                  equipped with the keys to their own work. It is my intention
                  to work alongside with kindness and a listening ear. I have a
                  deep and extensive array of{" "}
                  <Link
                    as='button'
                    
                    color='primary'
                    fontWeight='600'
                    onClick={onOpen}
                    aria-label='Read more about the qualifications'
                  >
                    qualifications
                  </Link>
                  .
                </p>
              </Stack>
            </Box>
          </Center>
        </SimpleGrid>
      </Box>
      <PopupModal
        isOpen={isOpen}
        onClose={onClose}
        finalFocusRef={btnRef}
        heading='I have the following qualifications:'
      >
        <UnorderedList spacing={2} mb={8}>
          {qualifications.map((qualification) => (
            <ListItem key={uuid()}>{qualification}</ListItem>
          ))}
        </UnorderedList>
      </PopupModal>
    </>
  );
};
