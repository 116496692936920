import Input from "./input";

type Dict = Record<string, any>;

const baseStyle = {
  ...Input.baseStyle,
  paddingY: "1rem",
  minHeight: "80px",
  lineHeight: "short",
  // _placeholder: {
  //   color: 'black'
  // }
};

const variants = {
  filled: (props: Dict) => Input.variants.filled(props).field,
};

const sizes = {
  md: Input.sizes.md.field,
};

const defaultProps = {
  size: "md",
  variant: "filled",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  baseStyle,
  sizes,
  variants,
  defaultProps,
};
