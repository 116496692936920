import { createIcon } from "@chakra-ui/react";
import React from "react";

export const InfoIcon = createIcon({
  displayName: "InfoIcon",
  viewBox: "0 0 20 20",
  path: (
    <>
      <circle cx="10" cy="10" r="10" fill="currentColor" />
      <path d="M9,7.3h2V5.7H9V7.3z M9,14.4h2V8.2H9V14.4z" />
    </>
  ),
});

export default InfoIcon;
