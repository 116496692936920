import { createIcon } from "@chakra-ui/react";
import React from "react";

export const FavouriteIcon = createIcon({
  displayName: "FavouriteIcon",
  viewBox: "0 0 30 30",
  path: (
    <path
      fill="currentColor"
      d="M26.3,8.1L26.3,8.1c-0.5-0.9-1.1-1.7-2-2.3c-0.8-0.6-1.8-1-2.8-1.2c-1-0.2-2-0.1-3,0.2c-1,0.3-1.8,0.8-2.6,1.5
	l-1,0.9l-1-0.9c-0.7-0.7-1.6-1.2-2.6-1.5c-1-0.3-2-0.4-3-0.2s-2,0.6-2.8,1.2C4.8,6.4,4.2,7.1,3.7,8l0,0c-0.6,1.2-0.8,2.6-0.6,3.9
	c0.2,1.3,0.9,2.5,1.8,3.5l9.5,9.8c0.2,0.2,0.4,0.3,0.6,0.3c0.2,0,0.4-0.1,0.6-0.3l9.5-9.8c1-0.9,1.6-2.2,1.8-3.5
	C27.1,10.6,26.9,9.3,26.3,8.1z M25.3,11.7c-0.2,1-0.6,1.9-1.3,2.6l0,0L15,23.5l-8.9-9.2l0,0c-0.7-0.7-1.2-1.6-1.3-2.6
	c-0.2-1,0-2,0.4-2.9C5.5,8.1,6,7.6,6.6,7.1C7.2,6.7,8,6.4,8.7,6.2c0.7-0.1,1.5-0.1,2.2,0.2c0.7,0.2,1.4,0.6,1.9,1.2c0,0,0,0,0,0
	L14.4,9c0.3,0.3,0.8,0.3,1.1,0l1.6-1.4c0,0,0,0,0,0c0.5-0.5,1.2-0.9,1.9-1.2c0.7-0.2,1.5-0.3,2.2-0.2c0.7,0.1,1.5,0.4,2.1,0.9
	c0.6,0.4,1.1,1,1.5,1.7C25.3,9.7,25.4,10.7,25.3,11.7z"
    />
  ),
});

export default FavouriteIcon;
