import { Box } from "@chakra-ui/react";
import WebpImage from "components/WebpImage";
import { useEffect, useState, useRef } from "react";

export const Spinner = () => {
  const [scrollAmount, setScrollAmount] = useState(0);
  const spinnerRef = useRef<HTMLImageElement>(null);

  const boxSize = "600px";

  /* spin animation */

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollTop = window.pageYOffset;
  //     const maxScroll =
  //       document.documentElement.scrollHeight - window.innerHeight;
  //     const scrollPercentage = scrollTop / maxScroll;
  //     const maxRotation = 360;

  //     setScrollAmount(scrollPercentage * maxRotation);
  //   };

  //   const handleIntersect = (entries: IntersectionObserverEntry[]) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         window.addEventListener("scroll", handleScroll);
  //         // console.log("entry.isIntersecting", entry.isIntersecting);
  //         return () => {
  //           window.removeEventListener("scroll", handleScroll);
  //         };
  //       }
  //     });
  //   };

  //   const observer = new IntersectionObserver(handleIntersect, {
  //     threshold: 0.125,
  //   });

  //   if (spinnerRef.current) {
  //     observer.observe(spinnerRef.current);
  //   }

  //   return () => {
  //     if (spinnerRef.current) {
  //       observer.unobserve(spinnerRef.current);
  //     }
  //   };
  // }, []);

  return (
    <Box
      ref={spinnerRef}
      id='spinner'
      // transform={`rotate(${scrollAmount}deg)`}
      // transition='transform 5s ease-out'
      boxSize={{ lg: boxSize }}
      borderRadius='full'
      bgColor={"sa_blue.400"}
      // overflow={"hidden"}
    >
      <WebpImage
        width={{ base: "full", lg: boxSize }}
        height={{ base: "full", lg: boxSize }}
        objectFit='contain'
        imgName='shell'
        // isPNG
        isForceVisible
        borderRadius='full'
      />
    </Box>
  );
};

export default Spinner;
