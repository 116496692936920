import { createIcon } from "@chakra-ui/react";
// the basic icon template to start with
export const LocationIcon = createIcon({
  displayName: "LocationIcon",
  viewBox: "0 0 48 48",
  path: (
    <path
      fill='currentColor'
      d='M24 23.5q1.45 0 2.475-1.025Q27.5 21.45 27.5 20q0-1.45-1.025-2.475Q25.45 16.5 24 16.5q-1.45 0-2.475 1.025Q20.5 18.55 20.5 20q0 1.45 1.025 2.475Q22.55 23.5 24 23.5ZM24 44q-8.05-6.85-12.025-12.725Q8 25.4 8 20.4q0-7.5 4.825-11.95Q17.65 4 24 4q6.35 0 11.175 4.45Q40 12.9 40 20.4q0 5-3.975 10.875T24 44Z'
    />
  ),
});

