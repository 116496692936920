import { Link, LinkProps } from '@chakra-ui/react';
import SITE_CONFIG from 'constants/siteConfig';
import { phoneNumberLink } from 'helpers/formatLink';

import { EmailIcon, PhoneIcon, LocationIcon } from 'theme/ui-components/icons';

const ContactLink = ({ ...props }: LinkProps) => (
  <Link color='sa_blue.700' fontWeight='700' textDecor='none' {...props} />
);

interface Props {
  showTollNumber?: boolean;
  addressInfo?: string;
}

export const ContactDetails = ({
  showTollNumber,
  addressInfo = "",
}: Props) => (
  <>
    <ContactLink
      href={phoneNumberLink(SITE_CONFIG.CONTACT_NUMBER)}
      aria-label={`Call: ${SITE_CONFIG.CONTACT_NUMBER}`}
    >
      <PhoneIcon boxSize={6} color='sa_teal.700' mr={4} />
      {showTollNumber && '0800 800 726 / '}
      {SITE_CONFIG.CONTACT_NUMBER}
    </ContactLink>
    <ContactLink
      href={`mailto:${SITE_CONFIG.EMAIL}`}
      aria-label={SITE_CONFIG.EMAIL}
    >
      <EmailIcon boxSize={6} color='sa_teal.700' mr={4} />
      email me
    </ContactLink>
    <ContactLink
      href={`${SITE_CONFIG.ADDRESS_URL}`}
      isExternal
      aria-label={SITE_CONFIG.ADDRESS + " Nelson"}
    >
      <LocationIcon boxSize={6} color='sa_teal.700' mr={4} />
      {SITE_CONFIG.ADDRESS}, {SITE_CONFIG.CITY} {!!addressInfo && <span>{addressInfo}</span>}
    </ContactLink>
  </>
);
