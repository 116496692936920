import {
  biodynamicCraniosacral,
  japaneseAcpuncture,
  spiritualHealing,
  remoteHealing,
} from "data";

export const treatments = [
  {
    label: "Japanese Acupuncture",
    imageName: "japanese-acupuncture",
    popupImage: "japanese-acupuncture-popup",
    description: japaneseAcpuncture.description,
    testimonials: japaneseAcpuncture.testimonials,
  },
  {
    label: "Biodynamic Craniosacral Therapy (BCST)",
    imageName: "treatment-biodynamic",
    popupImage: "craniosacral-popup",
    description: biodynamicCraniosacral.description,
    testimonials: biodynamicCraniosacral.testimonials,
  },
  {
    label: "Spiritual Healing",
    imageName: "treatment-spireitual",
    popupImage: "spiritual-healing-popup",
    description: spiritualHealing.description,
    testimonials: spiritualHealing.testimonials,
    additional: spiritualHealing.additional,
  },
  {
    label: "Remote Healing",
    imageName: "treatment-healing",
    popupImage: "remote-healing-popup",
    description: remoteHealing.description,
    testimonials: remoteHealing.testimonials,
  },
];
