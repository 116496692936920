import { color } from "@chakra-ui/react";

const colors = {
  transparent: "transparent",
  grey: {
    100: "#D7D7D7",
    200: "#9ca3af",
    300: "#757575",
    400: "#4d4d4d",
    500: "#6A7587",
    600: "#4C5662",
  },
  sa_blue: {
    100: "#EBEFF5",
    200: "#AFBDD8",
    300: "#728CBA",
    400: "#365B9D",
    500: "#365B9D",
    600: "#365B9D",
    700: "#333366",
    800: "#333366",
  },
  auburn: {
    400: "#9D222A",
  },
  sa_teal: {
    100: "#FCFDFD",
    200: "#F3F9F9",
    // 300: "#E8F2F3",
    300: "#ccd7d4",
    400: "#BEDCDC",
    500: "#87A5A5",
    600: "#578E8E",
    700: "#578E8E",
  },
  deepblue: {
    10: "#F8F8F9",
    50: "#F3F4F5",
    100: "#E6E8EB",
    200: "#CDD1D7",
    300: "#6A7587",
    400: "#EEF4F4",
    500: "#071937",
    600: "#040F20",
  },
  orange: {
    50: "#FCEEEA",
    500: "#DF532D",
    600: "#D24620",
  },
  green: {
    400: "#6EC248",
    500: "#2fa84f",
  },
  teal: {
    100: "#EEF4F4",
    200: "#DDEAEA",
    300: "#90D0D0",
    500: "#22a2a2",
    600: "#209898",
    700: "#1E8D8D",
  },
};
const aliases = {
  primary: colors.sa_blue[400],
  secondary: colors.sa_teal[400],
  tertiary: colors.sa_teal[500],
  surfaceBlue: colors.teal[500],
  steelTeal: colors.teal[700],
  columbiaBlue: colors.sa_teal[400],
  morningBlue: colors.sa_teal[500],
  dazzledBlue: colors.sa_blue[400],
  deepKoamaru: colors.sa_blue[700],
  deepWaterBlue: colors.deepblue[500],
  westCoastSand: colors.grey[600],
  success: colors.green[400],
  error: colors.auburn[400],
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...colors,
  ...aliases,
};
