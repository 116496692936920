import {
  Box,
  BoxProps,
  Container,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import WebpImage from "components/WebpImage";
import { modalContentStyle, testimonialParaStyle } from "constants/globalstyle";
import SPACING from "constants/spacing";
import parse from "html-react-parser";
import { useRef } from "react";
import { H2, H3, H4 } from "theme/ui-components/Typography";

interface Props extends BoxProps {
  DATA: any;
}

export const TreatmentSection = ({ DATA, ...props }: Props) => {
  return (
    <Box as='section' {...props}>
      <Container>
        <H2 color='sa_teal.600' textAlign='center' mb={{ base: 8, lg: 12 }}>
          Treatments
        </H2>
        {/* <Flex textAlign="center" flexDir={{ base: "column", lg: "row" }}> */}
        <SimpleGrid columns={{ md: 2, lg: 4 }} spacingX={0} spacingY={10}>
          {DATA.map((item: any) => (
            <Tiles
              key={item.imageName}
              label={item.label}
              description={item.description}
              testimonials={item.testimonials}
              additional={item.additional}
              imageName={item.imageName}
              popupImage={item.popupImage}
            />
          ))}
        </SimpleGrid>
        {/* </Flex> */}
      </Container>
    </Box>
  );
};

interface TileProps {
  imageName: string;
  popupImage?: string;
  label: string;
  description: string;
  testimonials: string;
  additional?: string;
}
const Tiles = ({
  imageName,
  label,
  description,
  testimonials,
  additional,
  popupImage,
}: TileProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const btnRef = useRef(null);
  return (
    <>
      <VStack
        spacing={7}
        justifyContent='space-between'
        textAlign='center'
        onClick={onOpen}
        ref={btnRef}
        cursor='pointer'
      >
        <H3 color='primary' fontSize='22px'>
          {label}
        </H3>
        <Box bgColor='sa_blue.100'>
          <WebpImage
            imgName={imageName}
            boxSize='full'
            minW='300px'
            minH='300px'
            width={{ base: "100%" }}
            alt={label + " treatments"}
            _hover={{ opacity: 0.54 }}
          />
        </Box>
      </VStack>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size='3xl'
        finalFocusRef={btnRef}
        scrollBehavior='inside'
        isCentered
      >
        <ModalOverlay />
        <ModalContent pt={16} pb={SPACING.PADDING} px={{ base: 6, lg: 14 }}>
          <ModalHeader color='primary' fontSize={24}>
            {label}
          </ModalHeader>
          <ModalCloseButton
            fontSize='24px'
            boxSize='48px'
            borderRadius='full'
            top={6}
            right={6}
            _focus={{
              outline: "none",
            }}
          />
          <ModalBody sx={modalContentStyle}>
            {popupImage && (
              <WebpImage
                w={{ base: "100%", md: "33%" }}
                height='full'
                objectFit='cover'
                imgName={popupImage}
                float='right'
                mb={4}
                ml={{ lg: 4 }}
              />
            )}

            <div>{parse(description)}</div>

            {testimonials && (
              <Box mt={10}>
                <H4 mb={4} fontSize='lg'>
                  Testimonials
                </H4>
                <Box sx={testimonialParaStyle}>{parse(testimonials)}</Box>
              </Box>
            )}

            {additional && (
              <Box mt={10}>
                <H4 mb={4} fontSize='lg'>
                  Treatment for Vaccine Injury
                </H4>
                <Box sx={testimonialParaStyle}>{parse(additional)}</Box>
              </Box>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
