import * as Yup from "yup";
const NZMobileRegExp = /^02[0-2,6-9](\s|-|)\d{3,4}(\s|-|)\d{3,4}$/;
const CaptureSchema = Yup.object().shape({
  from_name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Your name is required"),

  from_email: Yup.string()
    .email("Please enter a valid email")
    .required("Email address is required"),
  mobilenumber: Yup.string()
    .min(7, "Too Short!")
    .max(16, "Too Long!")
    .required("Please enter a valid mobile number")
    .matches(NZMobileRegExp, "Phone number is not valid"),
  message: Yup.string()
    .min(2, "Too Short!")
    .required("Your message is required"),
});
export default CaptureSchema;
