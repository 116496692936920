import { Box, Container, Flex, Link, Stack, BoxProps } from '@chakra-ui/react';
import ContactDetails from 'components/ContactDetails';
import WebpImage from 'components/WebpImage';
import SPACING from 'constants/spacing';
import { H2 } from 'theme/ui-components/Typography';
import { ContactForm } from './ContactForm';
import SITE_CONFIG from 'constants/siteConfig';

const ContactSection = ({ ...props }: BoxProps) => {
  return (
    <Box as='section' {...props}>
      <Container py={SPACING.PADDING} maxW='container.lg'>
        <Flex gridGap={8} flexDirection={{ base: 'column', md: 'row' }}>
          <Box w={{ base: 'full', md: '45%' }}>
            <H2 color={'primary'} mb={14}>
              Contact
            </H2>
            <Stack mb={8} spacing={6}>
              <ContactDetails addressInfo=' (upstairs, rooms 2 and 3)' />
            </Stack>
            <Stack spacing={2} maxW={{ lg: '350px' }}>
              <Link
                isExternal
                href={SITE_CONFIG.ADDRESS_URL}
                aria-label={SITE_CONFIG.ADDRESS + " Nelson"}
              >
                <WebpImage
                  w={{ base: 'full', lg: 'unset' }}
                  imgName='map-159hardy'
                  alt={`map of ${SITE_CONFIG.SITE_NAME}`}
                  objectFit={'cover'}
                  sx={{
                    aspectRatio: '6/4',
                  }}
                />
              </Link>
              <WebpImage
                w={{ base: 'full', lg: 'unset' }}
                imgName='img-waiting-room'
                alt={`wating room at ${SITE_CONFIG.SITE_NAME}`}
                objectFit={'cover'}
                sx={{
                  aspectRatio: '6/4',
                }}
              />
            </Stack>
          </Box>
          <Box w={{ base: 'full', md: '55%' }}>
            <ContactForm />
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};
export default ContactSection;
