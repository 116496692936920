import { Box, BoxProps, Container, Text } from "@chakra-ui/react";
import { Headline } from "theme/ui-components/Typography";
import Spinner from "./Spinner";

interface Props extends BoxProps {
  introText: string;
}
export const LandingScreen = ({ introText = "", ...props }: Props) => {
  return (
    <Box
      as='section'
      {...props}
      position={"relative"}
      _after={{
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        display: "block",
        w: "full",
        h: "full",
        bg: "whiteAlpha.300",
        zIndex: 1,
      }}
    >
      <Container
        display='flex'
        flexDir='column'
        alignItems='center'
        w='full'
        justifyContent='center'
        position='relative'
        zIndex={2}
      >
        <Box
          my={8}
          position='relative'
          minW='375px'
          minH='375px'
          py={6}
          overflow={"hidden"}
        >
          <Spinner />
          <Box
            pos='absolute'
            display='flex'
            // bgColor="whiteAlpha.700"
            boxSize='full'
            justifyContent='center'
            flexDir='column'
            // margin="auto 0"
            // bottom={0}
            top={0}
            color='white'
            textAlign='center'
          >
            <Headline>
              Stillpoint Acupuncture <br />
              <Text fontSize={{ base: "16px", lg: "26px" }}>
                & Biodynamic Craniosacral Therapy
              </Text>
            </Headline>
          </Box>
        </Box>
        <Text maxW='768px' textAlign={{ lg: "center" }} margin='auto'>
          {introText}
        </Text>
      </Container>
    </Box>
  );
};
