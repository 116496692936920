export const navLinks = [
  // {
  //   menuItem: "home",
  //   uri: "/"
  // },
  {
    menuItem: "treatments",
    // uri: "/treatments"
  },
  {
    menuItem: "about me",
  },
  {
    menuItem: "FAQ",
  },
  {
    menuItem: "contact",
  },
];

// const extraLinks = [
//   {
//     menuItem: "privacy",
//   },
// ];

// export const footerLinks = navLinks.concat(extraLinks);
