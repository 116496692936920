import { createIcon } from "@chakra-ui/react";
import React from "react";

export const ExpandRoundedIcon = createIcon({
  displayName: "ExpandRoundedIcon",
  viewBox: "0 0 30 30",
  path: (
    <>
      <path fill='currentColor'
        d="M15,0C6.7,0,0,6.7,0,15s6.7,15,15,15s15-6.7,15-15S23.3,0,15,0z M15,28.5c-7.5,0-13.5-6-13.5-13.5
	S7.5,1.5,15,1.5s13.5,6,13.5,13.5S22.5,28.5,15,28.5z"
      />
      <path fill='currentColor'
        d="M18.8,11.7l-3.7,0.1c-0.3,0-0.6-0.2-0.6-0.6c0-0.3,0.2-0.6,0.6-0.6l4.1-0.1c0.1,0,0.2,0,0.3,0.1
	c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.2,0.1,0.3L20,15.5c0,0.3-0.3,0.6-0.6,0.6c-0.3,0-0.6-0.3-0.6-0.6
	L18.8,11.7z"
      />
      <path fill='currentColor'
        d="M11.7,18.8l3.7-0.1c0.3,0,0.6,0.2,0.6,0.6c0,0.3-0.2,0.6-0.6,0.6L11.3,20c-0.1,0-0.2,0-0.3-0.1
	c-0.1,0-0.2-0.1-0.3-0.2s-0.1-0.2-0.2-0.3c0-0.1-0.1-0.2-0.1-0.3l0.1-4.1c0-0.3,0.3-0.6,0.6-0.6s0.6,0.3,0.6,0.6V18.8z"
      />
    </>
  ),
});

export default ExpandRoundedIcon;
