import {
  Button,
  Container,
  Heading,
  UnorderedList,
  ListItem,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import React from "react";
import { SmallText } from "theme/ui-components/Typography";

export default function NoMatch() {
  return (
    <Container py='10vh' px='5vw'>
      <VStack
        h='full'
        justifyContent='center'
        spacing={{ base: 4, lg: 6 }}
        alignItems='left'
      >
        <Heading as='h1'>404 - Page Not Found</Heading>

        <Text className=''>
          The page you were looking for could not be found on our server. This
          might be because:
        </Text>
        <UnorderedList spacing={1} listStylePos='inside'>
          <ListItem>The page has been removed or renamed</ListItem>
          <ListItem>The page no longer exists</ListItem>
          <ListItem>The URL you entered is incorrect</ListItem>
        </UnorderedList>
        <Text className=''>
          We apologize for any inconvenience this may have caused. Please try
          the following
        </Text>
        <UnorderedList spacing={1} listStylePos='inside'>
          <ListItem>Check the URL for typos</ListItem>
          <ListItem>
            Use the navigation menu to find what you are looking for
          </ListItem>
          <ListItem>
            Contact our support team if you continue to have problems
          </ListItem>
        </UnorderedList>
        <Text className=''>Thank you for your understanding</Text>

        <div>
          <Button
            as='div'
            py={7}
            px={9}
            borderRadius='full'
            colorScheme='sa_blue'
            _hover={{
              bgColor: "sa_blue.500",
            }}
          >
            <Link to='/'>Return to homepage</Link>
          </Button>
        </div>
      </VStack>
    </Container>
  );
}
