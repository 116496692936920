import { extendTheme, withDefaultColorScheme } from "@chakra-ui/react";
// Global style overrides
import styles from "./styles";

import colors from "./foundations/colors";

// Component style overrides
import {
  Button,
  Switch,
  Container,
  Checkbox,
  Radio,
  Link,
  Input,
  Select,
  Textarea,
} from "./components/";
import fonts from "./fonts";

// Foundational style overrides
import { breakpoints, sizes } from "./foundations/";

// 2. Add your color mode config
const config: any = {
  // initialColorMode: "dark",
  // useSystemColorMode: true,
};

export default extendTheme(
  {
    config,
    styles,
    colors,
    fonts,
    sizes,
    breakpoints,
    // Other foundational style overrides go here
    components: {
      // Other components go here
      Checkbox,
      // Button,
      Radio,
      Switch,
      Link,
      Input,
      Select,
      Textarea,
      Container,
    },
  },
  withDefaultColorScheme({ colorScheme: "sa_teal" })
);
