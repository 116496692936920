import { createIcon } from "@chakra-ui/react";
import React from "react";

export const TwitterIcon = createIcon({
  displayName: "TwitterIcon",
  viewBox: "0 0 30 30",
  path: (
    <path fill='currentColor'
      d="M27.5,7.2c-0.9,0.4-1.9,0.7-2.9,0.8c1.1-0.6,1.9-1.6,2.3-2.8c-1,0.6-2.1,1-3.3,1.2c-0.9-1-2.3-1.6-3.7-1.6
	c-3.3,0-5.7,3.1-5,6.3c-4.3-0.2-8-2.3-10.6-5.4c-1.3,2.3-0.7,5.3,1.6,6.8c-0.8,0-1.6-0.3-2.3-0.6c-0.1,2.4,1.6,4.6,4.1,5.1
	c-0.7,0.2-1.5,0.2-2.3,0.1c0.7,2,2.5,3.5,4.8,3.6c-2.2,1.7-4.9,2.4-7.6,2.1c2.3,1.5,5,2.3,7.9,2.3c9.5,0,14.9-8,14.6-15.3
	C25.9,9.2,26.8,8.3,27.5,7.2z"
    />
  ),
});

export default TwitterIcon;
