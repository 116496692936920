import { createIcon } from "@chakra-ui/react";

export const HamburgerIcon = createIcon({
  displayName: "HamburgerIcon",
  viewBox: "0 0 30 30",
  path: (
    <>
      <rect width="30" height="4" rx="1" y="2" fill="currentColor" />
      <rect width="30" height="4" rx="1" y="12" fill="currentColor" />
      <rect width="30" height="4" rx="1" y="22" fill="currentColor" />
    </>
  ),
});
