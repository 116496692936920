import { createIcon } from "@chakra-ui/react";
import React from "react";

export const PlayIcon = createIcon({
  displayName: "PlayIcon",
  viewBox: "0 0 30 30",
  path: (
    <>
      <path
        fill="currentColor"
        className="outline"
        d="M15,1.8C7.7,1.8,1.8,7.7,1.8,15c0,7.3,5.9,13.2,13.2,13.2c7.3,0,13.2-5.9,13.2-13.2C28.2,7.7,22.3,1.8,15,1.8z
	 M0,15C0,6.7,6.7,0,15,0c8.3,0,15,6.7,15,15c0,8.3-6.7,15-15,15C6.7,30,0,23.3,0,15z"
      />
      <path
        fill="currentColor"
        className="trangle"
        d="M11.5,9.6c0.3-0.2,0.6-0.2,0.9,0l8.4,4.8c0.3,0.2,0.5,0.5,0.5,0.8c0,0.3-0.2,0.6-0.5,0.8l-8.4,4.8
	c-0.3,0.2-0.6,0.2-0.9,0C11.2,20.6,11,20.3,11,20v-9.6C11,10.1,11.2,9.8,11.5,9.6z M12.9,12v6.4l5.7-3.2L12.9,12z"
      />
    </>
  ),
});

export default PlayIcon;
