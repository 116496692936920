export const modalContentStyle = {
  p: {
    mb: 4,
    _last: {
      mb: 1.5,
    },
  },
  h6: {
    fontWeight: "700",
    fontSize: '1.125em',
    mb: 2,
  },
};
export const testimonialParaStyle = {
  p: {
    fontStyle: "italic",
    // _after: {
    //   content: '"',
    // },
  },
  i: {
    fontStyle: "normal",
    fontWeight: "500",
    mb: 6,
    display: "block",
  },
};
