import { createIcon } from "@chakra-ui/react";

export const CloseIcon = createIcon({
  displayName: "CloseIcon",
  viewBox: "0 0 30 30",
  d:
    "M16.3 15l6.4-6.4c.4-.4.4-.9 0-1.3s-.9-.4-1.3 0L15 13.7 8.6 7.3c-.4-.4-.9-.4-1.3 0s-.4.9 0 1.3l6.4 6.4-6.4 6.4c-.4.4-.4.9 0 1.3s.9.4 1.3 0l6.4-6.4 6.4 6.4c.4.4.9.4 1.3 0s.4-.9 0-1.3L16.3 15z",
});

export default CloseIcon;
