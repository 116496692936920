export const biodynamicCraniosacral = {
  description: `
<p>Biodynamic Craniosacral Therapy ( BCST ) is a deeply relaxing experience. You will be guided through stages of deep relaxation, body scanning, deep breathing and resourcing.</p>
<p>The session takes about 40 to 50 minutes and you will remained clothed and comfortable under soft heated towels.</p>
<p>The practitioner is able to tune into subtle fluid flows throughout the body and will often hold an area that most needs balancing.</p>
<p>You may notice as the session progresses a sense of expansion and movement within your body. Through deep rest and resourcing, the body can restore and settle, and dysfunctional patterns can then resolve.</p>
<p>This is because you are connecting with your inherent health, the deep matrix of your being which is innate since inception.</p>
<p>This body intelligence helps us heal from all sorts of things from a cut finger to complex organ diseases.</p>
<p>BCST is very beneficial for head, neck and back injuries as well as stress induced conditions such as high blood pressure, headaches, anxiety and insomnia.</p>
<p>Please text me if you are unsure if your condition can be helped with BCST.</p>
  `,
  testimonials: `
<p>I have been coming to see Charlotte at Stillpoint Acupuncture Clinic since 2018 when I had a serious head injury due to a car accident. </p>
<p>This injury further resulted in post-concussion syndrome and massive incapacitating headaches. I have also suffered PTSD since the accident. </p>
<p>Charlotte's sessions have calmed much of the anxiety and my headaches are significantly reduced as well. I have returned to a normal, healthier lifestyle now. </p>
<p>I am forever grateful for the care she has provided me and recommend her work.</p>
<i>DR. Nelson</i>
`,
};
