// base style for the <Container />
// container sizes are define at `src/theme/foundations/sizes.ts`
const baseStyle = {
  maxW: "container.xl",
  // py: [6, 8, 12, 16, 20],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  baseStyle,
};
