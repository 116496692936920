import {
  Box,
  Container,
  Divider,
  HStack,
  Link,
  List,
  ListItem,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { navLinks } from "data/linkItems";
import { formatLink } from "helpers";
import { useRef } from "react";
import { PopupModal } from "../PopupModal";
import { Copyright } from "./Copyright";

const Footer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef(null);
  return (
    <>
      <Box
        as='footer'
        color='white'
        bgColor='sa_blue.400'
        py={{ base: 4, lg: 8 }}
        fontSize='sm'
        sx={{
          a: {
            fontWeight: "600",
            color: "whiteAlpha.800",
            _hover: {
              color: "white",
            },
          },
        }}
      >
        <Container
          py={0}
          display='flex'
          flexDirection={{ base: "column", lg: "row" }}
          justifyContent='space-between'
        >
          <HStack spacing={3}>
            <Copyright />
            <Divider orientation='vertical' h='50%' />
            <Box opacity={0.7}>
              Website by{" "}
              <a
                href='https://archieweb.nz'
                target='_blank'
                rel='noopener noreferrer'
                aria-label='ArchieWeb.nz'
              >
                ArchieWeb
              </a>
            </Box>
          </HStack>
          <List
            display='flex'
            flexDirection={{ base: "column", lg: "row" }}
            my={{ base: 4, lg: 0 }}
            sx={{
              "a, button": {
                mx: { lg: 2.5 },
                textDecor: "none",
                textTransform: "capitalize",
              },
            }}
          >
            {navLinks.map((item: any) => (
              <ListItem key={item.menuItem.toString()}>
                <Link href={`#${formatLink(item.menuItem)}`}>
                  <Text as='span'>{item.menuItem}</Text>
                </Link>
              </ListItem>
            ))}
            <ListItem>
              <Link
                as='button'
                onClick={onOpen}
                color='white'
                display='inline-block'
                fontWeight='bold'
              >
                Privacy Policy
              </Link>
            </ListItem>
          </List>
        </Container>
      </Box>
      <PopupModal
        isOpen={isOpen}
        onClose={onClose}
        finalFocusRef={btnRef}
        heading='Privacy Policy'
      >
        <p>
          I, Charlotte Stuart, respect your privacy in the use, storage and
          security of the data you provide.
        </p>
        <p>
          I collect contact details, personal health and lifestyle data to
          enable me to give relevant, effective treatments. The contact form
          collects names, email addresses and phone numbers as well as enquiry
          messages.
        </p>
        <p>
          Only I have access to this information. The Information is retained
          for as long as required to meet the purposes stated above.
        </p>
        <p>
          You can request to receive a copy of the personal data I hold about
          you, including any data you have provided to us. You can also request
          that I erase any personal data held about you. This does not include
          any data I am obliged to keep for administrative, legal, or security
          purposes. If you believe I have collected incorrect information on
          you, or if you would like to dispute any information I hold, please
          contact me.
        </p>
      </PopupModal>
    </>
  );
};

export default Footer;
