import { createIcon } from "@chakra-ui/react";
import React from "react";

export const TickIcon = createIcon({
  displayName: "TickIcon",
  viewBox: "0 0 30 30",
  path: (
    <path fill='currentColor'
      d="M27.2,5.5c0.9,0.8,1.1,2.2,0.3,3.1l-12.4,15c-0.4,0.5-0.9,0.7-1.5,0.8c-0.6,0.1-1.2-0.1-1.6-0.5l-8.2-7.1
	c-0.9-0.8-1-2.2-0.2-3.1c0.8-0.9,2.2-1,3.1-0.2l6.5,5.6L24.1,5.8C24.9,4.9,26.3,4.7,27.2,5.5z"
    />
  ),
});

export default TickIcon;
