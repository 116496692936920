import { mode } from "@chakra-ui/theme-tools";

type Dict = Record<string, any>;

function baseStyle(props: Dict) {
  const { colorScheme: c } = props;
  return {
    textDecoration: "underline",
    color: mode(`${c}.500`, `${c}.400`)(props),
    _hover: {
      color: mode(`${c}.600`, `${c}.700`)(props),
    },
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  baseStyle,
};
