import { createIcon } from "@chakra-ui/react";
import React from "react";

export const ExternalLinkIcon = createIcon({
  displayName: "ExternalLinkIcon",
  viewBox: "0 0 30 30",
  path: (
    <>
      <path fill='currentColor'
        d="M22.6,22.6c0,0.7-0.6,1.3-1.3,1.3l-14,0c-0.7,0-1.3-0.6-1.3-1.3L6,8.6c0-0.7,0.6-1.3,1.3-1.3h8.3
	c0.4,0,0.6,0.3,0.6,0.6c0,0.4-0.3,0.6-0.6,0.6H7.3l0,14l14,0v-8.3c0-0.4,0.3-0.6,0.6-0.6c0.4,0,0.6,0.3,0.6,0.6V22.6z"
      />
      <path fill='currentColor'
        d="M24,6.6L24,6.6l0,5.1c0,0.3-0.2,0.6-0.5,0.6l-0.1,0c-0.4,0-0.6-0.3-0.6-0.6l0-3.5L15,16c-0.2,0.2-0.7,0.2-0.9,0
	c-0.2-0.2-0.2-0.7,0-0.9l7.8-7.8l-3.7,0c-0.4,0-0.6-0.3-0.6-0.6S17.9,6,18.2,6l5.1,0C23.7,6,24,6.3,24,6.6z"
      />
    </>
  ),
});

export default ExternalLinkIcon;
