import { createIcon } from "@chakra-ui/react";
import React from "react";

export const QuoteDownIcon = createIcon({
  displayName: "QuoteDownIcon",
  viewBox: "0 0 30 30",
  path: (
    <>
      <path fill='currentColor'
        d="M27.5,17.2h-4.2v-1.1c0-5.3,2.5-7.3,6.7-7.3V4.8c-7.4,0-11.5,4.7-11.5,11.4v9.1h8.9V17.2z M9.9,17.2H5.8v-1.1
	c0-5.3,2.5-7.3,6.7-7.3V4.8C5.1,4.8,1,9.4,1,16.2v9.1h8.9V17.2z"
      />
    </>
  ),
});

export default QuoteDownIcon;
