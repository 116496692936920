export const faq = [
  {
    title: "Can I see my Osteopath or Physio at the same time",
    bodyText: `
   <p>
    Acupuncture and Craniosacral Therapy Compliment other Modalities. Acupuncture
    and craniosacral therapy are complete healing systems. I often work in close
    partnership with the above therapists and have noticed much better results
    with clients when these multiple modalities are involved.
  </p>
  <p>
    Osteopathy and Chiropractic care… acupuncture softens tight muscles and
    releases anti-inflammatory chemicals so that these mechanical adjustments can
    hold.
  </p>
  <p>
    Physiotherapy… acupuncture loosens the fascia and muscles from the inside so
    that the stretching exercises you are given in physiotherapy are more
    effective.
  </p>
  <p>
    Massage… acupuncture address's the underlying reasons for stress and tight
    muscles such as organ imbalances so that a massage is more easily assimilated
    by the body.
  </p>
  <p>Psychotherapy Acupuncture calms the stress response cycle.</p>  
      `,
  },

  {
    title: "How does Biodynamic Cranio-Sacral Therapy work?",
    faqImage: "stillpointacupuncture_qa",
    bodyText: `
<p>We might think of our body as never changing or moving, like an image in the mirror each day, it looks the same. But inside the body there is a constant turnover of molecules and cells. For example, the cells of our stomach lining are replaced every three or four days. Our liver is renewed every 6 weeks, even our bones which seem so solid are actually fluid in the sense that they are continually renewed, completing a cycle every 3-4 years. In fact in a scant 7 years every atom in our body has been changed!</p>
<p>The body is constantly in motion; the heart beats over a 100,000 times a day, the diaphragm expands and contacts 24,000 times a day. We are made up of 75% water. Fluids constantly flow within and through our arteries, organs, fascia and bones in rhythmic patterns</p>
<p>In Biodynamic Craniosacral Therapy (BCST) we are interested in keeping this internal environment moving and flowing. In health our body is constantly moving, changing and regulating itself. When the body is unable to move due to trauma, inflammation or disease.. then problems arise.. kind of like a rock in the middle of a stream that would create turbulence.</p>
<p>Dr Sutherland, one of the founders of Osteopathy noticed that the cranial bones were bevelled like the gills of a fish and wondered why this was so.. that it seemed like the bones of the cranium were designed to move. After experimenting on himself with a helmet he concluded that the whole cranium expanded and contracted rhythmically and he called the force behind this The Breath of Life. He felt that this movement.. this force was making the corrections in his patients not himself! Sutherland also found that this movement was an actual fluctuation of cerebral spinal fluid or CSF and noticed it was moving at particular tempos up and down the body.</p>
<p>This expansion and contraction can be felt throughout the entire body and is called Inhalation and Exhalation.. like breathing. This energy rises and expands through the whole body and then narrows and falls. This beautiful motion can be felt anywhere in the body due to the connectivity of fascia. Fascia is a type of connective tissue that wraps organs and muscles and is continuous from head to toe. Fascia is a wonderful communication system and it has tensile properties. Biodynamic Craniosacral therapists listen to it deeply.. often feeling structure a long way off from their palpating hands!</p>
<p>Sutherland realized that The Breath of Life.. this force that enlivens the whole body.. comes from a place of profound stillness and that from this stillness all the healing decisions are made. In BCST we orientate to stillness and this ever present health which arises from it. Health is seen to be all-pervasive and eternally present and is a deep organizing force.</p>
<p>BCST practitioners are trained to remain neutral without any intentions for change to happen. By sitting back but remaining engaged and empathetic a whole powerful response can happen. The body literally starts doing the work of healing itself in its own time and way. We call this The Inherent Treatment Plan.</p>
<p>The first session starts by taking an in depth case history of your present health, the family's health, and your childhood heath as well as any knowledge you have of your own birth. We also want to know what supports you and gives you a sense of well-being in your life. We may lightly place our hands at different locations on the body and head depending on what area's require attention.</p>
<p>During the session it is good to relax and enjoy the process. If you feel strong sensations or feelings coming up please share these if you feel to. The healing process is often a team approach. The practitioner might guide you through a meditation that can help create a sense of embodiment and calm.</p>
`,
  },
  {
    title: "How does Acupuncture work?",
    faqImage: "stillpointacupuncture_acupuncture_qa",
    bodyText: `<p>It is commonly believed that acupuncture is a pain control technique that resets the pain receptors and pathways of the peripheral nervous system.</p>
<p>Many people are surprised to learn that acupuncture can alter hormone levels, blood levels, digestive enzymes, stomach motility, endorphin levels and much more.</p>
<p>The ancient Chinese recognized the vital energy behind all life's processes, and life's forms. They called this energy “Qi” (pronounced “chee”). They discovered that the body contains meridians(pathways of circulating Qi) and that each meridian was associated with a physiological system andorgan. Through 23 centuries these doctors refined and experimented with this system, ascribing unique functions to each acupuncture point.</p>
<p>For example ST36 (Zusanli), a point just below the knee on the stomach channel can increase or decrease stomach motility, alter acid levels, raise white blood cell levels and decrease blood pressure (to name just a few functions).</p>
<h6>Does Acupuncture hurt?</h6>
<p>Receiving acupuncture bears no resemblance to the feeling of receiving an injection.</p>
<p>Acupuncture needles are solid and very fine about the diameter of a human hair. We use special insertion tubes, which allow for a quick painless insertion. The sensation of Qi once an acupuncture point is accessed is often described as a drawing sensation.</p>
<h6>Treatment</h6>
<p>Our goal in Acupuncture is to balance the body's organ systems and promote the circulation of Qi. We do this by inserting very fine needles into carefully chosen points in the feet, arms and torso, often to bring about change in areas surprisingly far away from that point!</p>
<p>Our diagnosis is made from a detailed case history and discussion about all your body systems. We will look at your tongue and palpate your pulses. We also palpate your meridians, testing for relative excess and deficiency. In this way we create an overall picture of your health rather than isolating any one particular symptom.</p>
`,
  },
  {
    title: "What's Your Cancellation Policy?",
    bodyText:
      "Please give at least 24 hours notice if you would like to cancel or change your appointment, otherwise you will be charged for a full session, this includes ACC patients.",
  },
  {
    title: "How should I Pay",
    bodyText:
      "Cash Payment or Internet Banking (please note treatment date in reference) on the day of visit.",
  },
  {
    title: "Do you work under ACC?",
    bodyText:
      "I am an ACC provider. My surcharge is $35. As an acupuncturist I can't initiate the claim for you. Please see your Doctor, Physio, Osteopath or Chiropractor.",
  },
];
