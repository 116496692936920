import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  IconButton,
  Link,
  List,
  ListItem,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { formatLink } from "helpers";
import { HamburgerIcon } from "theme/ui-components/icons";
import { navLinks } from "data/linkItems";
import ContactDetails from "components/ContactDetails";

export default function MobileMenu() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  // console.log("color mode: " + colorMode);
  const delayClose = () => {
    setTimeout(onClose, 800);
  };
  return (
    <>
      <IconButton
        border='none'
        boxSize={10}
        display='flex'
        color='sa_blue.700'
        justifyContent='center'
        alignItems='center'
        aria-label='open navigation'
        bgColor='transparent'
        icon={<HamburgerIcon boxSize={8} />}
        onClick={onOpen}
        _hover={{
          cursor: "pointer",
          backgroundColor: "rgba(255, 255, 255, 0.05)",
        }}
        _active={{
          backgroundColor: "rgba(255, 255, 255, 0.05)",
        }}
      />

      <Drawer isOpen={isOpen} placement='left' onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton
              fontSize='24px'
              boxSize='48px'
              borderRadius='full'
              top={6}
              right={6}
            />

            <DrawerBody>
              <Box h='full' display='flex' alignItems='center'>
                <Box as='nav' className='mobile-menu' pl={4}>
                  <List spacing={2}>
                    {navLinks.map((item: any) => (
                      <ListItem key={item.menuItem.toString()}>
                        <Link
                          fontWeight='bold'
                          color='primary'
                          textTransform='capitalize'
                          fontSize='1.25rem'
                          href={
                            item.uri
                              ? item.uri
                              : `#${formatLink(item.menuItem)}`
                          }
                          onClick={delayClose}
                        >
                          <Text as='span'>{item.menuItem}</Text>
                        </Link>
                      </ListItem>
                    ))}
                  </List>
                  <Stack spacing={4} mt={16}>
                    <ContactDetails />
                  </Stack>
                </Box>
              </Box>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
}
