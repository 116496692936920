import { createIcon } from "@chakra-ui/react";
import React from "react";

export const SearchIcon = createIcon({
  displayName: "SearchIcon",
  viewBox: "0 0 30 30",
  path: (
    <>
      <path fill='currentColor'
        d="M25.7,23.1l-6.3-6.3c1.1-1.4,1.6-3.1,1.6-4.9c0-2.1-0.8-4.2-2.4-5.7C17.2,4.8,15.2,4,13,4
	c-2.1,0-4.2,0.8-5.7,2.4S5,9.9,5,12c0,2.1,0.8,4.2,2.4,5.7S10.9,20,13,20c1.8,0,3.5-0.6,4.9-1.6l6.3,6.3c0.4,0.4,1.1,0.4,1.5,0
	C26.1,24.3,26.1,23.6,25.7,23.1z M17.2,16.2c-1.1,1.1-2.6,1.7-4.1,1.7c-1.5,0-3-0.6-4.1-1.7c-1.1-1.1-1.7-2.6-1.7-4.1
	c0-1.6,0.6-3,1.7-4.1s2.6-1.7,4.1-1.7c1.6,0,3,0.6,4.1,1.7c1.1,1.1,1.7,2.6,1.7,4.1C18.9,13.6,18.3,15.1,17.2,16.2z"
      />
    </>
  ),
});

export default SearchIcon;
