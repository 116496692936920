import { createIcon } from "@chakra-ui/react";
import React from "react";

export const ShowPasswordIcon = createIcon({
  displayName: "ShowPasswordIcon",
  viewBox: "0 0 30 30",
  path: (
    <>
      <path fill='currentColor'
        d="M15,6.3C7.5,6.3,2.5,15,2.5,15s5,8.7,12.5,8.7c7.5,0,12.5-8.7,12.5-8.7S22.5,6.3,15,6.3z M15,21.1
		c-3.4,0-6.1-2.8-6.1-6.1c0-3.4,2.8-6.1,6.1-6.1c3.4,0,6.1,2.8,6.1,6.1C21.1,18.4,18.4,21.1,15,21.1z"
      />
      <path fill='currentColor' d="M15,12.3c-1.5,0-2.7,1.3-2.7,2.8c0,1.5,1.2,2.7,2.7,2.7c1.5,0,2.8-1.2,2.8-2.7C17.8,13.5,16.5,12.3,15,12.3z" />
    </>
  ),
});

export default ShowPasswordIcon;
