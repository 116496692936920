import { Heading, HeadingProps } from "@chakra-ui/react";

export const H1 = (props: HeadingProps) => (
  <Heading
    as="h1"
    fontSize={{ base: "36", lg: "52" }}
    // lineHeight="1.12"
    // letterSpacing="-2px"
    {...props}
  />
);

export const Headline = (props: HeadingProps) => (
  <H1
    fontSize={{ base: "24", md: "32", lg: "40" }}
    lineHeight="1.125"
    {...props}
  />
);

export const H2 = (props: HeadingProps) => (
  <Heading
    as="h2"
    fontSize={{ base: "32", lg: "48" }}
    letterSpacing="-0.01em"
    // lineHeight="1.15"
    // marginBottom={8}
    {...props}
  />
);

export const H3 = (props: HeadingProps) => (
  <Heading
    as="h3"
    fontSize={{ base: "20", lg: "24" }}
    // lineHeight="1.375"
    // marginBottom={6}
    {...props}
  />
);

export const H4 = (props: HeadingProps) => (
  <Heading
    as="h4"
    fontSize={{ base: "20", lg: "26" }}
    lineHeight="1.3"
    // marginBottom={5}
    {...props}
  />
);
export const H5 = (props: HeadingProps) => (
  <Heading
    as="h5"
    fontSize={{ base: "16", lg: "18" }}
    lineHeight="1.4"
    // marginBottom={4}
    {...props}
  />
);
export const H6 = (props: HeadingProps) => (
  <Heading
    as="h6"
    fontSize={{ base: "14", lg: "16" }}
    // marginBottom={3}
    {...props}
  />
);
