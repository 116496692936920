import { createIcon } from "@chakra-ui/react";
import React from "react";

export const BackIcon = createIcon({
  displayName: "BackIcon",
  viewBox: "0 0 30 30",
  path: (
    <>
      <path
        fill="currentColor"
        d="M21,8.5H9c-3.6,0-6.5,2.9-6.5,6.5s2.9,6.5,6.5,6.5h12c3.6,0,6.5-2.9,6.5-6.5S24.6,8.5,21,8.5z M21,20.6H9
	c-3.2,0-5.6-2.5-5.6-5.6S5.9,9.4,9,9.4h12c3.1,0,5.6,2.5,5.6,5.6S24.1,20.6,21,20.6z"
      />
      <path
        fill="currentColor"
        d="M10.4,17.9l-2.6-2.6c-0.2-0.2-0.2-0.4,0-0.6l2.6-2.6c0.2-0.2,0.4-0.2,0.6,0c0.2,0.2,0.2,0.4,0,0.6l-1.9,1.9
	h11.1v0.8H9.1l1.9,1.9c0.2,0.2,0.2,0.4,0,0.6C10.8,18,10.6,18,10.4,17.9z"
      />
    </>
  ),
});

export default BackIcon;
