import { createIcon } from "@chakra-ui/react";
import React from "react";

export const ChevronIcon = createIcon({
  displayName: "ChevronIcon",
  viewBox: "0 0 30 30",
  path: (
    <path
      fill="currentColor"
      d="M10.8,7.4c0.5-0.5,1.2-0.5,1.7,0l6.8,6.8c0.5,0.5,0.5,1.2,0,1.7l-6.8,6.8c-0.5,0.5-1.2,0.5-1.7,0
	c-0.5-0.5-0.5-1.2,0-1.7l5.9-5.9l-5.9-5.9C10.3,8.6,10.3,7.8,10.8,7.4z"
    />
  ),
});

export default ChevronIcon;
