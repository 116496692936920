import { Box, BoxProps, Container, Text } from "@chakra-ui/react";
import WebpImage from "components/WebpImage";
import parse from "html-react-parser";

interface Props extends BoxProps {
  imgName: string;
  poem?: string;
}
export const TreatmentRoom = ({ imgName, poem }: Props) => (
  <Box as='section' position='relative' w='full' maxW='1920px' mx='auto'>
    <WebpImage
      imgName={imgName}
      objectFit='cover'
      zIndex={1}
      w='full'
      h='auto'
      maxH='800px'
      alt={`${process.env.REACT_APP_TITLE} treatment room`}
    />
    <Box
      position={{ base: "relative", lg: "absolute" }}
      display='flex'
      alignItems='center'
      boxSize='full'
      top={0}
      left={0}
      zIndex={99}
    >
      {poem && (
        <Container maxW='container.xl'>
          <Text
            bg='whiteAlpha.800'
            borderRadius='18px'
            lineHeight='tall'
            fontSize={{ lg: "xl" }}
            maxW={{ lg: "50%" }}
            width='fit-content'
            p={{ base: 10, lg: 16 }}
            sx={{
              backdropFilter: "blur(10px)",
            }}
          >
            <Text as='i' fontWeight='500'>
              {parse(poem)}
            </Text>
          </Text>
        </Container>
      )}
    </Box>
  </Box>
);
