import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from "@chakra-ui/react";
import { modalContentStyle } from "constants/globalstyle";
import SPACING from "constants/spacing";

interface Props extends ModalProps {
  heading?: string;
}

export const PopupModal = ({ heading, children, ...props }: Props) => {
  return (
    <Modal size='full' scrollBehavior='inside' isCentered {...props}>
      <ModalOverlay />
      <ModalContent
        pt={16}
        pb={SPACING.PADDING}
        px={{ base: 2, lg: 14 }}
        maxW={{ base: "100vh", lg: "container.md" }}
        minH={{lg:'unset'}}
      >
        <ModalHeader color='primary' fontSize={24}>
          {heading}
        </ModalHeader>
        <ModalCloseButton
          fontSize='24px'
          boxSize='48px'
          borderRadius='full'
          top={{ base: 3, lg: 6 }}
          right={{ base: 3, lg: 6 }}
        />
        <ModalBody sx={modalContentStyle}>{children}</ModalBody>
      </ModalContent>
    </Modal>
  );
};
