import { createIcon } from "@chakra-ui/react";
import React from "react";

export const QuoteIcon = createIcon({
  displayName: "QuoteIcon",
  viewBox: "0 0 30 30",
  path: (
    <>
      <path
        fill="currentColor"
        d="M3.5,12.8h4v1.1c0,5.1-2.5,7-6.5,7v3.9c7.1,0,11.1-4.5,11.1-11V5.1H3.5V12.8z"
      />
      <path
        fill="currentColor"
        d="M20.4,12.8h4v1.1c0,5.1-2.5,7-6.5,7v3.9c7.1,0,11.1-4.5,11.1-11V5.1h-8.6V12.8z"
      />
    </>
  ),
});

export default QuoteIcon;
