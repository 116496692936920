import { yearsOfExperience } from 'constants/index';
import SITE_CONFIG from 'constants/siteConfig';

export const introText = `
Hello, I'm Charlotte Stuart. Welcome to my website. I offer
Japanese Acupuncture, Craniosacral Therapy and Spiritual Healing. 
With over ${yearsOfExperience} years of experience to draw from as an
acupuncturist, I seek to resolve deeply held patterns and habits
within the body and mind which delay health and well-being. Through
gentle enquiry and a light touch I aim to give health the opportunity
to emerge and blossom. I look forward to meeting you. 
Stillpoint Acupuncture is now located in the heart of Nelson. upstairs at ${SITE_CONFIG.ADDRESS}, rooms 2 and 3.
`;
