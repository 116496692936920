import { createIcon } from "@chakra-ui/react";
import React from "react";

export const MinusIcon = createIcon({
  displayName: "MinusIcon",
  viewBox: "0 0 30 30",
  path: (
    <path fill='currentColor'
      d="M2.5 15c0-.6.5-1.1 1.1-1.1h22.7c.6 0 1.1.5 1.1 1.1 0 .6-.5 1.1-1.1 1.1H3.6c-.6 0-1.1-.5-1.1-1.1z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  ),
});

export default MinusIcon;
