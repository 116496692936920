import { createIcon } from "@chakra-ui/react";
import React from "react";

export const LocationiconIcon = createIcon({
  displayName: "LocationiconIcon",
  viewBox: "0 0 30 30",
  path: (
    <>
      <path fill='currentColor'
        d="M15,27.7l-0.5-0.5c-0.2-0.3-0.5-0.6-0.9-1c-0.8-0.9-1.9-2.3-3.1-3.9c-1.3-1.8-2.4-3.6-3.1-5.2c-0.9-2-1.4-3.8-1.4-5.3
	c0-5,4.1-9.1,9.1-9.1s9.1,4.1,9.1,9.1c0,1.5-0.5,3.3-1.4,5.3c-0.8,1.6-1.8,3.3-3.1,5.2c-1.2,1.6-2.3,3-3.1,3.9
	c-0.4,0.4-0.7,0.8-0.9,1L15,27.7z M15,4c-4.2,0-7.7,3.4-7.7,7.7c0,1.3,0.4,2.9,1.3,4.7c0.7,1.5,1.7,3.2,3,4.9c1.1,1.6,2.3,2.9,3,3.8
	c0.1,0.2,0.3,0.3,0.4,0.4c0.1-0.1,0.2-0.3,0.4-0.4c0.8-0.9,1.9-2.2,3-3.8c1.3-1.8,2.3-3.4,3-4.9c0.9-1.8,1.3-3.4,1.3-4.7
	C22.7,7.5,19.2,4,15,4z"
      />
      <path fill='currentColor'
        d="M15,16c-2.1,0-3.9-1.7-3.9-3.9c0-2.1,1.7-3.9,3.9-3.9s3.9,1.7,3.9,3.9S17.1,16,15,16z M15,9.6c-1.4,0-2.5,1.1-2.5,2.5
	s1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5C17.5,10.8,16.4,9.6,15,9.6z"
      />
    </>
  ),
});

export default LocationiconIcon;
