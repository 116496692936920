export const remoteHealing = {
  description: `
<p>
  Energy work is just as effective when done at a distance. This means you are
  able to relax in your own home while the healing is taking place.
</p>
<p>
  I recommend you find a quiet and private space to rest, where you feel warm
  and comfortable. We will catch-up on the phone before for an introduction and
  discussion of your concerns and then afterwards to process what came up. My
  clients often report feeling relaxed and energized as well as amazed by all
  the new sensations in their body that have arisen during the session.
</p>
  `,
  testimonials: `
<p>
I have been fortunate enough to have had a session of improved clairvoyance with
Charlotte, while not my usual "go to" in terms of ironing out the wrinkles, this
experience had significant positive effect both at the time and unfolding for a
long time afterward!
</p>
<p>
I really felt a profound shift in my entire self and garnered a greater understanding of my overall state of existence.
</p>
<p>I am truly grateful.</p>
<i>P, Nelson</i>
  `,
};
