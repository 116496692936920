import { createIcon } from "@chakra-ui/react";

export const ArrowIcon = createIcon({
  displayName: "ArrowIcon",
  viewBox: "0 0 30 30",
  d:
    "M22.5 10.2l4.8 4.8c.3.3.3.8 0 1.1l-4.8 4.8c-.3.3-.8.3-1.1 0-.3-.3-.3-.8 0-1.1l3.5-3.5H3.3c-.4 0-.8-.3-.8-.8 0-.4.3-.8.8-.8H25l-3.5-3.5c-.3-.3-.3-.8 0-1.1.2-.2.7-.2 1 .1z",
});

export default ArrowIcon;
