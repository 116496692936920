import { Container } from "@chakra-ui/react";
import ReactMarkdown from "react-markdown";

const gfm = require("remark-gfm");

const markdown = `

## TODO
* [ ] popup modal
* [x] basic contact info
* [ ] Uncaught ReferenceError
* [x] read more/less function
* [x] read more/less function

## use the same react test version as SWNZ 
- "@testing-library/jest-dom": "5.16.1",
- "@testing-library/react": "^12.1.2",

## May 2022
- solved chachra component not displaying styles in [storybook](https://github.com/storybookjs/storybook/issues/13114#issuecomment-846464338)
- add react helmet, but got Warning: Using UNSAFE_componentWillMount in strict mode is not recommended and may indicate bugs in your code.
- upload file to archieweb.host
- set up password protect with [.htaccess](https://sitebeginner.com/apache/passwordprotectdirectory/) file
- move the [.htpasswd](https://sitebeginner.com/apache/passwordprotectdirectory/) file to the correct path, /home/archieweb/...
- practice then write some test for Value Banner, read [getByAltText](https://testing-library.com/docs/queries/byalttext/), [practical-guide-testing](https://www.smashingmagazine.com/2020/06/practical-guide-testing-react-applications-jest/)
- [common mistake](https://kentcdodds.com/blog/common-mistakes-with-react-testing-library)

## Apr 2022
- upgrade React Router to V6
- pages refactor
- create value banner module

## Mar 2022
- navigation component refactor
- update key=object.object.toString();
- add formatLink = data.menuItem.split(" ").join("-").toLowerCase(); in order to convert 'home' to '/'
- add ScrollToTop to the App.tsx

## these need to update Jan 2022

- remove StoryBook
- update packages
- npm link webpack

### June
- try to set up the sticky scroll reveal header, but got conflict with the GoTopButton's **document.documentElement.scrollTop**
- add the [React Headroom](http://foodthinkers.com.au/ebook/breville_thefastslowcookerbook.pdf)
- read and add [React Form Validation With Formik And Yup](https://www.smashingmagazine.com/2020/10/react-validation-formik-yup/)

### May 2021 
- yarn add [animated-number-react](https://www.npmjs.com/package/animated-number-react)
- yarn add [react-intersection-observer](https://www.npmjs.com/package/react-intersection-observer)
- add animation and Scroll to reveal feature
- replacd style-components with the [px props](https://chakra-ui.com/docs/features/the-sx-prop)
- adding [react-intersection-observer](https://www.npmjs.com/package/react-intersection-observer)

### April 2021 
- replace most of major elements with Chrakra UI
- Add the StoryBook components, however the Chakra styles not showing up in stories
- might consider to remove the "storybook" from project


### install and learn [chakra](https://chakra-ui.com/docs/getting-started)
- yarn add @chakra-ui/react @emotion/react @emotion/styled framer-motion
- replace some elements with chakra Box
- yarn add remark-gfm

### Jan 2021 (14)
- Create the blox-react website template for futhure use
- migrant the projects to Mac Mini
- Fixed the starting issue by run "yarn install"
- add [custom focus](https://uxdesign.cc/accessible-custom-focus-indicators-da4768d1fb7b) indicators on input fields
- add [pure css loading](https://loading.io/css/) animation and made the loading screen transition more smoothly
- add ftp deploy.js styleguilde page.
- create the ViewMore component
- add home banner component 

`;

const ChangeLog = () => (
  <Container>
    <ReactMarkdown plugins={[gfm]} className="changelog" children={markdown} />
  </Container>
);
export default ChangeLog;
