import { createIcon } from "@chakra-ui/react";
import React from "react";

export const HidePasswordIcon = createIcon({
  displayName: "HidePasswordIcon",
  viewBox: "0 0 30 30",
  path: (
    <>
      <path fill='currentColor'
        d="M26,13.8h-1.4c-2.1,2.9-5.5,4.6-9.1,4.6c-3.6,0-6.9-1.7-9.1-4.6H5L5.1,14c0.6,0.9,1.3,1.7,2.1,2.4l-1.9,2.4
	l0.9,0.7L8,17.1c0.8,0.6,1.7,1.1,2.6,1.5l-1.1,2.9l1,0.4l1.1-2.9c1.1,0.3,2.2,0.6,3.4,0.6v3h1.1v-3c1.1-0.1,2.2-0.3,3.3-0.6l1.2,2.9
	l1-0.4l-1.2-2.8c0.9-0.4,1.8-0.9,2.6-1.6l1.9,2.4l0.9-0.7L24,16.3c0.7-0.7,1.4-1.4,1.9-2.3L26,13.8z"
      />
    </>
  ),
});

export default HidePasswordIcon;
