import { createIcon } from "@chakra-ui/react";
import React from "react";
// the basic icon template to start with
export const AddUserIcon = createIcon({
  displayName: "AddUserIcon",
  viewBox: "0 0 60 60",
  path: (
    <>
      <path
        fill="white"
        stroke="currentColor"
        className="head"
        d="M44.063 16.875c-.46 6.196-5.157 11.25-10.313 11.25-5.156 0-9.861-5.053-10.313-11.25-.468-6.445 4.102-11.25 10.313-11.25s10.781 4.922 10.313 11.25z"
      />
      <path
        fill="white"
        className="body"
        stroke="currentColor"
        strokeMiterlimit="10"
        d="M33.75 35.625c-10.195 0-20.543 5.625-22.458 16.242-.234 1.28.494 2.508 1.834 2.508h41.249c1.34 0 2.065-1.228 1.834-2.508C54.293 41.25 43.945 35.625 33.75 35.625z"
      />
      <path
        fill="none"
        stroke="currentColor"
        d="M10.313 20.625V33.75M16.875 27.188H3.75"
        className="plus"
      />
    </>
  ),
});

export default AddUserIcon;
