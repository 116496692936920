// This is a React Router v6 app
import { Route, Routes as RouterRoutes } from "react-router-dom";
import { Homepage, NoMatch } from "./pages";
// import Treatments from 'pages/Treatments';

export const Routes = () => (
  <main>
    <RouterRoutes>
      <Route index element={<Homepage />} />
      {/* <Route path='/treatments' element={<Treatments />} /> */}
      <Route path='*' element={<NoMatch />} />
    </RouterRoutes>
  </main>
);
