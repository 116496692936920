export const japaneseAcpuncture = {
  description: `
<p>
  The form of Acupuncture that I practice was developed in Japan by Master
  Nagano. These teachings have been taught to western students by Kiko Matsumoto
  and her student Monica Kobylecka.
</p>
<p>
  Japanese Acupuncture is extremely gentle and light, using abdominal palpation
  as the main diagnostic tool. You will enjoy learning about reflexes, flows and
  releases as we work together as a team. You can expect to feel deeply relaxed
  and lightly energized as you receive these treatments and learn about healing
  in your body. In general acupuncture regulates the nervous system, calming
  stress and promoting rest and relaxation. This is achieved through stimulating
  acupuncture points, which are situated along energy lines called meridians.
</p>
<p>
  Through many thousands of years of empirical knowledge, each point has been
  known to affect the body in its own unique way. An example of this is UB67, a
  point on the very edge of the little toe that can stimulate a change of
  position when a baby is in breech. In Japanese acupuncture we use a lot of
  direct Moxa. This is the application of a spongy type punk prepared from
  Mugwort leaves.
</p>
<p>
  This is then rolled finely into a rice grain size and positioned directly on
  an acupuncture point and lit with an incense stick. The skin is protected with
  a special salve whilst the heat plunges down deep into the body, stimulating
  more blood flow and nourishing the immune system. Clients generally experience
  this as deeply supportive and calming.
</p>
<p>
  Needles are ultra thin and don't hurt with insertion, sometimes a small tug might be felt. Each needle used is sterile and disposed of after the treatment.
</p>
<p>
  Acupuncture can treat many conditions, because it is regulatory and far
  reaching. There are rare side effects but generally there are no hidden
  dangers to be concerned about.
</p>
<p>
  In your first session we discuss how acupuncture may affect your body and what
  to expect, initially you may need to come weekly to generate some good
  momentum for change.
</p>
<p>
  I see clients with all sorts of health concerns as well as those wanting
  general tuneups. It is best to dress casually in loose clothes and come to the
  session well hydrated, having eaten within the last few hours. After a session
  drink lots of water and rest as this allows the changes to take place.
</p>
  `,
  testimonials: `
<p>
I have had the great pleasure, ongoing healing and restoration that Charlotte and Stillpoint has offered over 17 years of my being here in Nelson.  Coming to a genuine stillpoint and the deep restoration that offers is profound. I would not be as well, strong, and vital as I continue to (mostly) feel at just coming up 70 years without her kind heart, hands and head over all these years. Thank you again and again.
</p>
<i>KD</i>
<p>
I have been a client of Charlotte's for 14 years for various ailments & reasons. I think her treatments are amazing & successful.
</p>
<i>MM</i>
<p>
Acupuncturists like Charlotte make the world a better place because she thinks of ways to make a positive difference in the lives of others, especially me.
Whether you are eliminating my pain, increasing my energy or addressing unresolved symptoms, you've made a healthier version of myself.  I feel you've worked with me to create a roadmap for optimal wellness.  
</p>
<i>Wendy</i>
   `,
};
