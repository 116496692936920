import { createIcon } from '@chakra-ui/react';
import React from 'react';

export const FavouriteCircle = createIcon({
  displayName: 'FavouriteCircle',
  viewBox: '0 0 40 40',
  path: (
    <>
      <circle
        cx="20"
        cy="20"
        r="19"
        stroke="currentColor"
        fill="none"
        strokeWidth="2"
      />
      <path
        className="inner-icon"
        d="M17.104 15.237a3.157 3.157 0 0 0-3.768 1.56 3.049 3.049 0 0 0 .585 3.553l.01.01 5.822 5.996 5.823-5.996.01-.01a3.05 3.05 0 0 0 .585-3.553 3.159 3.159 0 0 0-5.037-.788l-1.015.924a.543.543 0 0 1-.732 0l-1.014-.924a3.159 3.159 0 0 0-1.27-.772Zm9.55 1.313.486-.246a4.137 4.137 0 0 1-.789 4.817l-6.208 6.393a.544.544 0 0 1-.78 0l-6.208-6.393a4.135 4.135 0 0 1-.788-4.817l.485.246-.483-.25a4.244 4.244 0 0 1 6.744-1.088l.64.585.641-.585a4.245 4.245 0 0 1 6.744 1.089l-.483.249Z"
        fill="currentColor"
        strokeWidth="0.5"
        stroke="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});

export default FavouriteCircle;
