import { Button, Divider, Stack, Text } from "@chakra-ui/react";
import SITE_CONFIG from "constants/siteConfig";
import { send } from "emailjs-com";
import { Form, Formik } from "formik";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { H3 } from "theme/ui-components/Typography";
import { InputElementControl, TextareaControl } from "ui-form";
import CaptureSchema from "./formvalidation";

export const ContactForm = () => {
  const [captchaKey, setCaptchaKey] = useState(null);
  const [successfulSubmission, setSuccessfulSubmission] = useState(false);
  const [errorSubmission, setErrorSubmission] = useState(false);
  const submitted = !errorSubmission && !successfulSubmission;
  const errorSubmissionMsg =
    "There was an issue with the submission, please try again or call us: " +
    SITE_CONFIG.CONTACT_NUMBER;
  return successfulSubmission ? (
        <>
          <H3 as="h2">Message sent</H3>
          <Text>Thanks you, we will contact you shortly.</Text>
        </>
  ) : (
    <Formik
      initialValues={{
        from_name: "",
        from_email: "",
        mobilenumber: "",
        message: "",
      }}
      validationSchema={CaptureSchema}
      onSubmit={(values) => {
        // same shape as initial values
        const templateParams = {
          ...values,
          "g-recaptcha-response": captchaKey,
        };
        // templateParams: Object
        // Template parameters of the template.
        console.log(templateParams);
        send(
          SITE_CONFIG.EMAILJS_serviceID,
          SITE_CONFIG.EMAILJS_templateID,
          templateParams,
          SITE_CONFIG.EMAILJS_userID
        )
          .then((response) => {
            setSuccessfulSubmission(true);
            console.log("SUCCESS!", response.status, response.text);
          })
          .catch((err) => {
            setErrorSubmission(true);
            console.log("FAILED...", err);
          });
      }}
    >
      {({
        // values,
        dirty,
        isValid,
        isSubmitting,
        handleSubmit,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Stack spacing={5} mb={6} alignItems='flex-start'>
            <InputElementControl
              type='text'
              name='from_name'
              label='your name'
              isRequired
            />
            <InputElementControl
              type='email'
              name='from_email'
              label='your email'
              isRequired
            />

            <InputElementControl
              name='mobilenumber'
              type='tel'
              pattern='[0-9]*'
              label='your number'
              isRequired
            />

            <TextareaControl
              name='message'
              placeholder='Leave me a message'
              isRequired
            />
          </Stack>
          <Stack spacing={6}>
            {/* <Divider /> */}
            <ReCAPTCHA
              // theme='light'
              sitekey={SITE_CONFIG.RECAPTCHA_SITE_KEY}
              onChange={setCaptchaKey}
            />
            {errorSubmission && (
              <Text color='red.400' fontSize='sm' noOfLines={2}>
                {errorSubmissionMsg}
              </Text>
            )}
            {!(dirty && isValid && captchaKey !== null) && (
              <Text fontSize='sm' fontWeight={600} noOfLines={2}>
                * Please complete the above fields in order to submit the form.
              </Text>
            )}
            {/* <Divider /> */}
            <Button
              py={7}
              px={9}
              borderRadius='full'
              colorScheme='sa_blue'
              type='submit'
              isLoading={isSubmitting && submitted}
              _hover={{
                bgColor: "sa_blue.500",
              }}
              // disabled={
              //   (!(dirty && isValid) || isSubmitting || captchaKey === null) &&
              //   submitted
              // }
            >
              Submit
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
