import { createIcon } from "@chakra-ui/react";
import React from "react";

export const ChevronCircle = createIcon({
  displayName: "ChevronCircle",
  viewBox: "0 0 30 30",
  path: (
    <>
      <path
        className="chevron-circle--circle"
        fill="currentColor"
        d="M15,0C6.7,0,0,6.7,0,15s6.7,15,15,15s15-6.7,15-15S23.3,0,15,0z M15,28.5c-7.5,0-13.5-6-13.5-13.5
	S7.5,1.5,15,1.5s13.5,6,13.5,13.5S22.5,28.5,15,28.5z"
      />
      <path
        className="chevron-circle--chevron"
        fill="currentColor"
        d="M15.8,15l-2.7-2.6c-0.2-0.2-0.2-0.6,0-0.8s0.6-0.2,0.8,0l2.9,2.8c0.1,0.1,0.1,0.2,0.2,0.3
	c0,0.1,0.1,0.2,0.1,0.3s0,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.3l-2.9,2.8c-0.2,0.2-0.6,0.2-0.8,0s-0.2-0.6,0-0.8L15.8,15z"
      />
    </>
  ),
});

export default ChevronCircle;
