import { createIcon } from "@chakra-ui/react";
import React from "react";

export const PlusIcon = createIcon({
  displayName: "PlusIcon",
  viewBox: "0 0 30 30",
  path: (
    <path fill='currentColor'
      d="M15 2.5c.6 0 1 .5 1 1V14h10.4c.6 0 1 .5 1 1s-.5 1-1 1H16v10.4c0 .6-.5 1-1 1s-1-.5-1-1V16H3.5c-.6 0-1-.5-1-1s.5-1 1-1H14V3.5c0-.5.4-1 1-1z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  ),
});

export default PlusIcon;
