import { Box, BoxProps, Container } from "@chakra-ui/react";
import FaqAccordion from "theme/ui-components/elements/FaqAccordion";

interface Props extends BoxProps {
  accordionItems: any;
}

export const FaqSection = ({ accordionItems, ...props }: Props) => {
  return (
    <Box as="section" {...props}>
      <Container maxW='container.md'>
        <FaqAccordion heading='FAQ' accordionItems={accordionItems} />
      </Container>
    </Box>
  );
};
