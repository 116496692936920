import { createIcon } from "@chakra-ui/react";
import React from "react";

export const EnquiryIcon = createIcon({
  displayName: "EnquiryIcon",
  viewBox: "0 0 30 30",
  path: (
    <path fill='currentColor'
      d="M26.5,7.5C26.1,7.2,25.6,7,25.1,7H4.9c-0.5,0-1,0.2-1.3,0.5C3.2,7.9,3,8.4,3,8.9v12.3c0,0.5,0.2,1,0.5,1.3
	C3.9,22.8,4.4,23,4.9,23h20.3c0.5,0,1-0.2,1.3-0.5c0.3-0.4,0.5-0.8,0.5-1.3V8.9C27,8.4,26.8,7.9,26.5,7.5z M24.1,8.6l-8.8,7.9
	c-0.1,0.1-0.2,0.1-0.3,0.1s-0.2,0-0.3-0.1L5.9,8.6H24.1z M4.6,20.4V9.6l6,5.3L4.6,20.4z M5.8,21.4l5.9-5.4l1.8,1.7
	c0.4,0.3,0.9,0.5,1.4,0.5s1-0.2,1.4-0.5l1.8-1.7l5.9,5.4H5.8z M25.4,20.4l-6-5.4l6-5.3V20.4z"
    />
  ),
});

export default EnquiryIcon;
